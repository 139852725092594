<template>
  <div>
    <div class="d-flex align-items-center quantity-input-wrapper">
      <div class="quantity-input-controller">
        <button
          v-if="!hideIcons"
          :disabled="!isAvailable"
          class="plus-icon"
          :class="{ disabled: !isAvailable }"
          @blur="onBlur"
          @click="addQuantity"
        >
          <PlusCircledIcon class="order-quantity-action-icon" />
        </button>
        <input
          ref="inputRef"
          v-model="quantity"
          :disabled="!isAvailable"
          min="0"
          type="text"
          inputmode="numeric"
          :class="{
            'hide-input-border': !showInputBorder,
            'alert-quantity': hasAlert,
          }"
          @blur="onBlur"
          @keydown="handleKeydown"
          @keypress="preventInvalidCharacters"
          @input="emitQuantityChange"
          @focus="onFocus"
        />
        <button
          v-if="!hideIcons"
          class="minus-icon"
          :disabled="shouldMinusBeDisabled() || !isAvailable"
          :class="{ disabled: shouldMinusBeDisabled() || !isAvailable }"
          @blur="onBlur"
          @click="subtractQuantity"
        >
          <MinusCircledIcon class="order-quantity-action-icon" />
        </button>
      </div>
      <ProductLatestOrderData class="order-quantity-product-latest-order-data" :row-data="rowDataWithAlerts" />
    </div>
    <ActionsModal
      v-if="isActionsModalOpen"
      :toggle-dialog="isActionsModalOpen"
      :title="$t('orderProcessCart.dialog.title')"
      :content="$t('orderProcessCart.dialog.content', { productName: itemToSaveInCart.name })"
      :confirm-button-text="$t('orderProcessCart.dialog.confirmButtonText')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="onCloseActionsModal"
      @on-close="onCloseActionsModal"
      @on-confirm="addNotRecommendedItemToCart"
    />
  </div>
</template>
<script>
import { computed, onMounted, ref, watch, toRefs } from 'vue';
import { PlusCircledIcon, MinusCircledIcon } from '@/assets/icons';
import { ActionsModal, DIALOG_TYPES } from '@/modules/core';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import ProductLatestOrderData from './ProductLatestOrderData.vue';

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
};

const ALERT_QUANTITY_FALLBACK = '';
const DEBOUNCE_TIME_MS = 1000;
export default {
  components: {
    ProductLatestOrderData,
    PlusCircledIcon,
    MinusCircledIcon,
    ActionsModal,
  },
  props: {
    rowData: { type: Object, default: () => ({}) },
    showQuantityToAdd: { type: Boolean, default: false },
    debounce: { type: Number, default: DEBOUNCE_TIME_MS },
    disableMinusNumber: { type: Number, default: 0 },
    hasFocus: { type: Boolean, default: false },
    keyToRetrieveQuantity: { type: String, default: 'quantity' },
    hideIcons: { type: Boolean, default: false },
    showInputBorder: { type: Boolean, default: false },
    isStocktaking: { type: Boolean, default: false },
    isAvailable: { type: Boolean, default: true },
  },
  emits: ['on-quantity-change', 'on-quantity-save', 'on-move-to-next-press', 'on-blur'],
  setup(props, { emit, listeners }) {
    const rowData = toRefs(props).rowData;
    const quantity = ref(
      props.showQuantityToAdd ? rowData.value.quantityToAdd ?? 0 : rowData.value[props.keyToRetrieveQuantity]
    );
    const prevQuantity = ref(quantity.value);
    const itemToSaveInCart = ref({});
    const isActionsModalOpen = ref(false);
    const hasFocus = computed(() => props.hasFocus);
    const hasMoveToNextPressListener = Boolean(listeners['on-move-to-next-press']);
    const hasOnBlurListener = Boolean(listeners['on-blur']);

    watch(
      rowData,
      () => {
        if (!props.isStocktaking) {
          if (!props.showQuantityToAdd && rowData.value[props.keyToRetrieveQuantity] !== quantity.value) {
            quantity.value = rowData.value[props.keyToRetrieveQuantity];
            emit('on-quantity-change', { rowData: rowData.value, quantity: Number(quantity.value) });
          }
        }
      },
      { immediate: true, deep: true }
    );

    const modifyQuantity = (isAdd = true) => {
      if (quantity.value === 0 && !rowData.value?.quantity) {
        if (rowData.value.isRecommended === false) {
          isActionsModalOpen.value = true;
          itemToSaveInCart.value = rowData.value;
          return;
        } else if (!isAdd) {
          return;
        }
      }

      let defaultValueToAdd = 1;
      if (!isAdd) {
        defaultValueToAdd = -1;
      }
      const mathFunction = isAdd ? Math.ceil : Math.floor;

      const numberedQuantity = Number(quantity.value || 0);
      const currentQuantityIsInt = numberedQuantity % 1 === 0;
      const roundedQuantity = mathFunction(numberedQuantity);

      let valueToAdd = roundedQuantity - numberedQuantity;

      const newVal = currentQuantityIsInt ? numberedQuantity + defaultValueToAdd : numberedQuantity + valueToAdd;

      quantity.value = newVal >= 0 ? newVal : 0;
      emitQuantityChange();
    };

    const inputRef = ref(null);

    onMounted(() => {
      if (hasFocus.value) {
        inputRef.value.focus();
        inputRef.value.select();
      }
    });

    watch(hasFocus, (newVal) => {
      if (newVal && inputRef.value) {
        inputRef.value.focus();
        inputRef.value.select();
      }
    });

    const onFocus = () => {
      inputRef.value.select();
    };

    const addQuantity = () => {
      modifyQuantity();
    };

    const subtractQuantity = () => {
      modifyQuantity(false);
    };

    const preventInvalidCharacters = (event) => {
      const char = String.fromCharCode(event.charCode);

      const notNumberOrDecimalPoint = char !== '.' && (char < '0' || char > '9');
      const moreThanOneDecimalPoint = char === '.' && event.target.value.includes('.');
      if (notNumberOrDecimalPoint || moreThanOneDecimalPoint) {
        event.preventDefault();
      }
    };

    const getAlertData = (rowData) => {
      const alertOverQuantity =
        rowData[props.keyToRetrieveQuantity] > 0 && rowData[props.keyToRetrieveQuantity] >= rowData.quantityToAlertOver;
      const alertLessQuantity =
        rowData[props.keyToRetrieveQuantity] > 0 && rowData[props.keyToRetrieveQuantity] <= rowData.quantityToAlertLess;

      const alertQuantity = alertLessQuantity || alertOverQuantity;

      return {
        alertLessQuantity,
        alertOverQuantity,
        alertQuantity,
      };
    };

    const rowDataWithAlerts = computed(() => ({
      ...rowData.value,
      ...getAlertData(rowData.value),
    }));

    const hasAlert = computed(() => rowDataWithAlerts.value.alertQuantity);

    const emitSaveDebounced = debounce(() => {
      emit('on-quantity-save', { rowData: rowData.value, quantity: Number(quantity.value) });
    }, props.debounce);

    const emitQuantityChange = () => {
      const value = quantity.value.toString();
      const parts = value.split('.');
      let newQuantity = value;

      const totalDigits = parts[0].length + (parts[1] ? parts[1].length : 0);
      if (totalDigits > 4) {
        newQuantity = prevQuantity.value;
      }

      quantity.value = newQuantity;
      prevQuantity.value = newQuantity;
      emit('on-quantity-change', { rowData: rowData.value, quantity: newQuantity });
      emitSaveDebounced();
    };

    const shouldMinusBeDisabled = () => {
      if (!props.showQuantityToAdd) {
        return rowData.value.quantity <= props.disableMinusNumber;
      } else {
        return quantity.value <= props.disableMinusNumber;
      }
    };

    const handleKeydown = (event) => {
      if (hasMoveToNextPressListener && (event.key === 'Enter' || event.key === 'Tab')) {
        event.preventDefault();
        emit('on-move-to-next-press');
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        addQuantity();
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        subtractQuantity();
      }
    };

    const addNotRecommendedItemToCart = () => {
      quantity.value = 1;
      emit('on-quantity-save', { rowData: itemToSaveInCart.value, quantity: Number(1) });
      isActionsModalOpen.value = false;
    };

    const onCloseActionsModal = () => {
      itemToSaveInCart.value = {};
      quantity.value = 0;
      isActionsModalOpen.value = false;
    };

    const onBlur = () => {
      if (quantity.value?.toString()?.endsWith('.')) {
        quantity.value = quantity.value.slice(0, -1);
      } else if (!quantity.value) {
        quantity.value = 0;
      }

      quantity.value = Number(quantity.value);

      if (hasOnBlurListener) {
        emit('on-blur', rowData.value, quantity.value);
      }
    };

    return {
      DIALOG_TYPES,
      MISSING_DATA_TEXT,
      ALERT_QUANTITY_FALLBACK,
      quantity,
      hasAlert,
      itemToSaveInCart,
      rowDataWithAlerts,
      isActionsModalOpen,
      addQuantity,
      addNotRecommendedItemToCart,
      subtractQuantity,
      emitQuantityChange,
      onCloseActionsModal,
      shouldMinusBeDisabled,
      preventInvalidCharacters,
      inputRef,
      handleKeydown,
      onFocus,
      onBlur,
    };
  },
};
</script>
<style lang="scss" scoped>
.quantity-input-wrapper {
  .quantity-input-controller {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    .disabled {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        color: #9295a5;
      }
    }
    button {
      background: transparent;
      border: none;
      color: #9295a5;

      &.plus-icon {
        padding-right: 0px;
      }
      &.minus-icon {
        padding-left: 0px;
      }
    }

    input {
      width: 4rem;
      height: 2.5rem;
      background: transparent;
      text-align: center;
      direction: ltr;
      border-radius: 4px;
      border: 1px solid #c9d5dd;

      &:focus {
        border: 2px solid #3661dd;
      }

      &.hide-input-border {
        width: 2.65rem;
        outline: none !important;
        border: none !important;
      }

      &.alert-quantity {
        color: #e47e03;
      }
    }
  }
}
</style>

<style lang="scss">
.order-quantity-product-latest-order-data {
  display: none;
}

button:not(:disabled) {
  .order-quantity-action-icon {
    &:hover {
      rect {
        fill: #e3e4e6 !important;
      }
    }
  }
}
</style>
